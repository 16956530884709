import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout2'

const SchedulePost = ({ data, children }) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div　className="sched-monthly-body">
        <p className="mtitle">{data.mdx.frontmatter.date}</p>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
        <div className="archive-home"><p className="sched-archive"><a href="/sarchives">&lt;&lt;戻る</a></p></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "YYYY年MM月")
      }
      body
    }
  }
`

export default SchedulePost
